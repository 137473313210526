<template>
  <div class="about-cards">
    <about-card
      :no-border="noBorder"
      :title="$t('about.service_1_title')"
      :text="$t('about.service_1_text')"
      animation-link="/animations/USP_global.json"
      is-first
    ></about-card>
    <about-card
      :no-border="noBorder"
      :title="$t('about.service_2_title')"
      :text="$t('about.service_2_text')"
      animation-link="/animations/USP_you-are-there_v2.json"
    ></about-card>
    <about-card
      :no-border="noBorder"
      :title="$t('about.service_3_title')"
      :text="$t('about.service_3_text')"
      animation-link="/animations/USP_no-bidding.json"
    ></about-card>
    <about-card
      :no-border="noBorder"
      :title="$t('about.service_4_title')"
      :text="$t('about.service_4_text')"
      animation-link="/animations/USP_price_rev.json"
      is-last
    ></about-card>
  </div>
</template>

<script lang="ts">
import AboutCard from "~/components/AboutCard.vue";

export default {
  name: "AboutCards",
  components: {
    AboutCard,
  },
  props: {
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.about-cards {
  display: flex;
  gap: 23px;
  margin-bottom: 95px;

  @include tablet {
    flex-wrap: wrap;
    gap: 20px;
  }
}
</style>
