<template>
  <div
    ref="rootEl"
    class="about-card"
    :class="{ 'about-card--no-border': noBorder }"
    @mouseenter="mouseEnterOnAnim()"
    @mouseleave="mouseLeaveOnAnim()"
  >
    <Vue3Lottie
      ref="lottieEl"
      :animation-link="animationLink"
      :auto-play="false"
      :loop="isTablet"
    />
    <h3 class="about-card__title">{{ title }}</h3>
    <div class="about-card__text">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue3Lottie } from 'vue3-lottie'
import { computed, onMounted, ref } from 'vue'
import { isClient } from '@vueuse/core'
import { useViewTools } from '~/composables'

export default {
  name: 'AboutCards',
  components: {
    Vue3Lottie,
  },
  props: {
    animationLink: { type: String, required: true },
    noBorder: { type: Boolean, default: () => false },
    isFirst: { type: Boolean, default: () => false },
    isLast: { type: Boolean, default: () => false },
    title: { type: String, default: () => '' },
    text: { type: String, default: () => '' },
  },
  setup(props) {
    const lottieEl = ref<InstanceType<typeof Vue3Lottie>>(null)
    const rootEl = ref<HTMLElement>(null)
    const intersectionObserver = ref<IntersectionObserver>(null)
    const duration = computed(() => lottieEl.value?.getDuration(true))
    const viewTools = useViewTools()

    const mouseEnterOnAnim = () => {
      lottieEl.value.setDirection('forward')
      lottieEl.value.playSegments([0, duration.value / 2], true)
    }

    const mouseLeaveOnAnim = () => {
      lottieEl.value.pause()
      lottieEl.value.setDirection('reverse')
      lottieEl.value.play()
    }

    onMounted(() => {
      if (viewTools.isTablet.value && isClient) {
        const rootElHeight = rootEl.value.clientHeight
        const screenHeight = window.innerHeight

        intersectionObserver.value = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                lottieEl.value.play()
              } else {
                lottieEl.value?.pause?.()
              }
            })
          },
          {
            rootMargin:
              !props.isFirst && !props.isLast
                ? `-${
                    screenHeight - rootElHeight - 20
                  }px 0px -${rootElHeight}px 0px`
                : props.isFirst
                ? `-${screenHeight - rootElHeight - 20}px 0px 0px 0px`
                : `0px 0px -${rootElHeight}px 0px`,
          }
        )
        intersectionObserver.value.observe(rootEl.value)
      }
    })

    return {
      rootEl,
      lottieEl,
      ...viewTools,
      mouseEnterOnAnim,
      mouseLeaveOnAnim,
    }
  },
}
</script>

<style lang="scss">
.about-card {
  text-align: center;
  padding: 30px 30px 35px;
  flex: 0 0 calc(25% - 18px);
  border: 1px solid var(--hb-gray1);
  border-radius: 10px;
  background-color: #fff;

  &--no-border {
    border: 0;
  }

  @include tablet {
    flex: 0 0 calc(50% - 10px);
    padding-right: 20px;
    padding-left: 20px;
  }

  @include mobile {
    flex: 0 0 100%;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
  }

  img {
    width: 100%;
    height: 183px;
    object-fit: contain;
    object-position: bottom center;
    margin-bottom: 35px;
  }

  .lottie-animation-container {
    height: 183px;
  }
}
</style>
